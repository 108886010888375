import React, { ReactNode } from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';

interface SidebarProps {
  selectedScans: string[];
  onCompare: () => void;
  children: ReactNode;
}

const Sidebar = ({ selectedScans, onCompare, children }: SidebarProps) => (
  <Box sx={{ width: 250, borderRight: 1, borderColor: 'divider', p: 2, flexShrink: 0 }}>
    <Typography variant="h6" gutterBottom>Filters</Typography>
    {children}
    <Divider sx={{ my: 2 }} />
    <Typography variant="h6" gutterBottom>Comparison</Typography>
    <Box sx={{
      minHeight: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: 'background.paper',
      borderRadius: 1,
      p: 2,
    }}>
      {selectedScans.length > 0 ? (
        selectedScans.map(scan => (
          <Typography key={scan} variant="body2">{scan}</Typography>
        ))
      ) : (
        <Typography variant="body2" color="text.secondary" align="center">
          Select scans to compare
        </Typography>
      )}
    </Box>
    <Button
      variant="contained"
      onClick={onCompare}
      disabled={selectedScans.length < 2}
      sx={{ mt: 2 }}
    >
      Compare Scans
    </Button>
  </Box>
);

export default Sidebar;