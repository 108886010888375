import React from 'react';
import { TableCell, Typography } from "@mui/material";
import ConfidenceBar from '../ConfidenceBar';

interface ConfidenceCellProps {
  cellData: { negative: number; positive: number; averageScore: number | null } | undefined;
  displayMode: 'confidence' | 'score';
  isClickable: boolean;
  handleCellClick: () => void;
}

const ConfidenceCell = React.memo(({ cellData, displayMode, isClickable, handleCellClick }: ConfidenceCellProps) => {
  const getBackgroundColor = (score: number | null, isHover: boolean = false) => {
    if (score === null) return 'white';
    const hue = score * 120; // 0 (red) to 120 (green)
    const saturation = isHover ? '90%' : '80%';
    const lightness = isHover ? '65%' : '75%';
    return `hsl(${hue}, ${saturation}, ${lightness})`;
  };

  const renderConfidenceOrScore = () => {
    if (!cellData) {
      return <Typography>-</Typography>;
    }

    if (displayMode === 'score') {
      const score = cellData.averageScore !== null ? cellData.averageScore.toFixed(2) : '-';
      return (
        <Typography 
          sx={{ 
            textAlign: 'center', 
            fontSize: '0.875rem',
            lineHeight: '1',
            width: '100%'
          }}
        >
          {cellData.negative === 0 && cellData.positive === 0 ? '-' : score}
        </Typography>
      );
    }
    return <ConfidenceBar negative={cellData.negative} positive={cellData.positive} />;
  };

  return (
    <TableCell 
      sx={{ 
        p: 2, 
        cursor: isClickable ? 'pointer' : 'default',
        backgroundColor: displayMode === 'score' && cellData 
          ? getBackgroundColor(cellData.averageScore) 
          : 'white',
        '&:hover': isClickable ? {
          backgroundColor: displayMode === 'score' && cellData 
            ? getBackgroundColor(cellData.averageScore, true) 
            : (theme) => theme.palette.action.hover,
          transition: 'background-color 0.3s',
        } : {}
      }}
      onClick={isClickable ? handleCellClick : undefined}
    >
      {renderConfidenceOrScore()}
    </TableCell>
  );
});

export default ConfidenceCell;