import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { Sidebar, ScanList, FilterSection } from '../components';
import generateMockData from '../data/ks2-data';
import { KS2Data, WritingPiece, KS2Criteria } from '../types';

const ScanViewer: React.FC = () => {
  const [selectedScans, setSelectedScans] = useState<string[]>([]);
  const [data, setData] = useState<KS2Data | null>(null);
  const [selectedPieces, setSelectedPieces] = useState<WritingPiece[]>([]);
  const [criteriaScoreFilter, setCriteriaScoreFilter] = useState<{
    level: KS2Criteria['level'] | null;
    criteria: KS2Criteria | null;
    minScore: number;
    maxScore: number;
  } | null>(null);

  useEffect(() => {
    const mockData = generateMockData();
    setData(mockData);
    setSelectedPieces(mockData.writingPieces);
  }, []);

  const handleCompare = () => {
    console.log('Comparing scans:', selectedScans);
  };

  const allScans = useMemo(() => data?.pupils.flatMap(pupil => 
    pupil.writingPieceList.map(({ id, writingPiece, scan }) => ({
      id: id,
      pupilName: pupil.name,
      writingPiece: writingPiece,
      pageCount: scan.pageCount,
      class: pupil.class.name
    }))
  ) || [], [data]);

  const writingPieces: WritingPiece[] = data?.writingPieces || [];

  const filteredScans = useMemo(() => {
    let scans = allScans.filter(scan => 
      selectedPieces.some(piece => piece.name === scan.writingPiece.name)
    );

    if (criteriaScoreFilter?.level || criteriaScoreFilter?.criteria) {
      scans = scans.filter(scan => {
        const pupil = data?.pupils.find(p => p.name === scan.pupilName);
        const relevantScores = pupil?.stylus_criteria_scores.filter(s => 
          s.piece.id === scan.id &&
          (!criteriaScoreFilter.level || s.level.name === criteriaScoreFilter.level.name) &&
          (!criteriaScoreFilter.criteria || s.stylus_criteria.ks2_criteria.name === criteriaScoreFilter.criteria.name)
        );

        if (!relevantScores?.length) return false;

        const averageScore = relevantScores.reduce((sum, s) => sum + (s.score ?? 0), 0) / relevantScores.length;

        return averageScore >= criteriaScoreFilter.minScore && averageScore <= criteriaScoreFilter.maxScore;
      });
    }

    return scans;
  }, [selectedPieces, allScans, criteriaScoreFilter, data]);

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 68px)' }}>
      <Sidebar
        selectedScans={selectedScans}
        onCompare={handleCompare}
      >
        <FilterSection
          selectedPieces={selectedPieces}
          setSelectedPieces={setSelectedPieces}
          writingPieces={writingPieces}
          ks2Criteria={data?.ks2_criteria || []}
          levels={data?.levels || []}
          criteriaScoreFilter={criteriaScoreFilter}
          setCriteriaScoreFilter={setCriteriaScoreFilter}
        />
      </Sidebar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <ScanList
          scans={filteredScans}
          selectedScans={selectedScans}
          setSelectedScans={setSelectedScans}
          data={data!}
        />
      </Box>
    </Box>
  );
};

export default ScanViewer;