import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Box, IconButton, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material';
import { ArrowBack, OpenInNew, NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material';
import ScanDisplay from './ScanDisplay';

interface BaseDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  topSectionItems?: {
    label: string;
    value: string | number | React.ReactNode;
  }[];
  splitExamples: boolean;
  onSplitExamplesChange: (splitExamples: boolean) => void;
  groupBy?: 'criteria' | 'piece' | 'level' | 'strand';
  onGroupByChange?: (event: React.MouseEvent<HTMLElement>, newGroupBy: 'criteria' | 'piece' | 'level' | 'strand' | null) => void;
  examples?: {
    positive: Record<string, { example: string; piece: string; criteria: string; level: string; strand: string; label: string }[]>;
    negative: Record<string, { example: string; piece: string; criteria: string; level: string; strand: string; label: string }[]>;
  };
  groupByOptions?: ('criteria' | 'piece' | 'level' | 'strand')[];
}

type Example = {
  example: string;
  piece: string;
  criteria: string;
  level: string;
  strand: string;
  label: string;
  type: 'positive' | 'negative';
};

const TopSection = ({ items }: { items: { label: string; value: string | number | React.ReactNode }[] }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 2, mb: 2 }}>
    {items.map((item, index) => (
      <Box key={index} sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px', padding: 2, height: '100%' }}>
        <Typography variant="caption" sx={{ mb: 0.5 }}>{item.label}</Typography>
        {typeof item.value === 'string' || typeof item.value === 'number' ? (
          <Typography variant="body1">{item.value}</Typography>
        ) : (
          item.value
        )}
      </Box>
    ))}
  </Box>
);

const FeedbackSection = () => (
  <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
    <Box sx={{ flex: 1 }}>
      <Typography variant="h5" gutterBottom>Even better if:</Typography>
      <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
    </Box>
    <Box sx={{ flex: 1 }}>
      <Typography variant="h5" gutterBottom>What went well:</Typography>
      <Typography variant="body2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography>
    </Box>
  </Box>
);

const EvidenceControls = ({
  groupBy,
  onGroupByChange,
  groupByOptions,
  splitExamples,
  onSplitExamplesChange
}: {
  groupBy?: 'criteria' | 'piece' | 'level' | 'strand';
  onGroupByChange?: (event: React.MouseEvent<HTMLElement>, newGroupBy: 'criteria' | 'piece' | 'level' | 'strand' | null) => void;
  groupByOptions?: ('criteria' | 'piece' | 'level' | 'strand')[];
  splitExamples: boolean;
  onSplitExamplesChange: (splitExamples: boolean) => void;
}) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="h5" gutterBottom>Evidence</Typography>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mt: 2, backgroundColor: 'background.paper', padding: 2, borderRadius: 1, border: '1px solid rgba(0, 0, 0, 0.12)' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-end' }}>
        {groupBy && onGroupByChange && groupByOptions && (
          <Box>
            <Typography variant="caption" display="block" gutterBottom>Group by</Typography>
            <ToggleButtonGroup value={groupBy} exclusive onChange={onGroupByChange} aria-label="group evidence by" size="small">
              {groupByOptions.includes('piece') && <ToggleButton value="piece" aria-label="group by piece">Writing Piece</ToggleButton>}
              {groupByOptions.includes('level') && <ToggleButton value="level" aria-label="group by level">Level</ToggleButton>}
              {groupByOptions.includes('criteria') && <ToggleButton value="criteria" aria-label="group by criteria">Criteria</ToggleButton>}
              {groupByOptions.includes('strand') && <ToggleButton value="strand" aria-label="group by strand">Strand</ToggleButton>}
            </ToggleButtonGroup>
          </Box>
        )}
        <Box>
          <Typography variant="caption" display="block" gutterBottom>View</Typography>
          <ToggleButtonGroup
            value={splitExamples ? 'split' : 'combined'}
            exclusive
            onChange={(_, newValue) => newValue && onSplitExamplesChange(newValue === 'split')}
            aria-label="split evidence"
            size="small"
          >
            <ToggleButton value="combined" aria-label="combined view">Combined</ToggleButton>
            <ToggleButton value="split" aria-label="split view">Split</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default function BaseDialog({
  open,
  onClose,
  title,
  children,
  maxWidth = 'md',
  fullWidth = true,
  topSectionItems = [],
  splitExamples,
  onSplitExamplesChange,
  groupBy,
  onGroupByChange,
  examples,
  groupByOptions,
}: BaseDialogProps) {
  const [showScan, setShowScan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5;

  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

  const renderScan = () => (
    <ScanDisplay
      currentPage={currentPage}
      totalPages={totalPages}
      onPreviousPage={handlePreviousPage}
      onNextPage={handleNextPage}
    />
  );

  const renderColumn = (column: [string, Example[]][]) => (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {column.map(([key, exampleList]) => (
        <Box key={key} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px', padding: 2 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ color: 'text.secondary', fontWeight: 'bold', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: 1, marginBottom: 2 }}>
            {key}
          </Typography>
          {exampleList.map((ex, index) => (
            <Box key={index} sx={{ backgroundColor: ex.type === 'positive' ? 'rgba(76, 175, 80, 0.1)' : 'rgba(244, 67, 54, 0.1)', borderLeft: `4px solid ${ex.type === 'positive' ? '#4caf50' : '#f44336'}`, padding: 2, borderRadius: '4px', mb: 2 }}>
              <Typography variant="caption" sx={{ display: 'block', mb: 1, fontWeight: 'bold', color: 'text.secondary' }}>{ex.label}</Typography>
              <Typography variant="body2" sx={{ color: ex.type === 'positive' ? 'success.main' : 'error.main' }}>{ex.example}</Typography>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );

  const renderExamples = (examples: Record<string, Example[]>, splitExamples: boolean) => {
    if (splitExamples) {
      const positiveExamples: Record<string, Example[]> = {};
      const negativeExamples: Record<string, Example[]> = {};

      Object.entries(examples).forEach(([key, exampleList]) => {
        positiveExamples[key] = exampleList.filter(ex => ex.type === 'positive');
        negativeExamples[key] = exampleList.filter(ex => ex.type === 'negative');
      });

      return (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>{renderColumn(Object.entries(negativeExamples).filter(([_, exampleList]) => exampleList.length > 0))}</Box>
          <Box sx={{ flex: 1 }}>{renderColumn(Object.entries(positiveExamples).filter(([_, exampleList]) => exampleList.length > 0))}</Box>
        </Box>
      );
    }

    const createBalancedColumns = (sortedGroups: [string, Example[]][]) => {
      const leftColumn: [string, Example[]][] = [];
      const rightColumn: [string, Example[]][] = [];
      let leftColumnExamples = 0;
      let rightColumnExamples = 0;

      sortedGroups.forEach((group) => {
        if (leftColumnExamples <= rightColumnExamples) {
          leftColumn.push(group);
          leftColumnExamples += group[1].length;
        } else {
          rightColumn.push(group);
          rightColumnExamples += group[1].length;
        }
      });

      return [leftColumn, rightColumn];
    };

    const sortedGroups = Object.entries(examples).sort((a, b) => b[1].length - a[1].length);
    const [leftColumn, rightColumn] = createBalancedColumns(sortedGroups);

    return (
      <Box sx={{ display: 'flex', gap: 2 }}>
        {renderColumn(leftColumn)}
        {renderColumn(rightColumn)}
      </Box>
    );
  };

  const combineExamples = (examples?: BaseDialogProps['examples']) => {
    if (!examples) return {};
    const combined: Record<string, Example[]> = {};
    
    for (const [key, exampleList] of Object.entries({ ...examples.positive, ...examples.negative })) {
      combined[key] = [
        ...(examples.positive[key] || []).map(ex => ({ ...ex, type: 'positive' as const })),
        ...(examples.negative[key] || []).map(ex => ({ ...ex, type: 'negative' as const }))
      ];
    }
    
    return combined;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">{title}</Typography>
        <Button
          variant="outlined"
          startIcon={showScan ? <ArrowBack /> : <OpenInNew />}
          onClick={() => setShowScan(!showScan)}
        >
          {showScan ? 'Back to Details' : 'View Scan'}
        </Button>
      </DialogTitle>
      <DialogContent>
        {topSectionItems.length > 0 && <TopSection items={topSectionItems} />}
        {showScan ? (
          renderScan()
        ) : (
          <>
            <FeedbackSection />
            <Divider sx={{ my: 3 }} />
            <EvidenceControls
              groupBy={groupBy}
              onGroupByChange={onGroupByChange}
              groupByOptions={groupByOptions}
              splitExamples={splitExamples}
              onSplitExamplesChange={onSplitExamplesChange}
            />
          </>
        )}
        {examples && !showScan && (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 300px)' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, minHeight: 0 }}>
              <Box sx={{ overflowY: 'auto', flex: 1 }}>
                {renderExamples(combineExamples(examples), splitExamples)}
              </Box>
            </Box>
          </Box>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}