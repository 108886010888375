import React, { useMemo, useState } from 'react'
import { Box } from "@mui/material"
import { ConfidenceTable, FilterControls, PupilDetailsDialog, PupilDialog, CriteriaLevelStrandDialog } from '../components';
import generateMockData from '../data/ks2-data'
import { calculateConfidence } from '../utils'
import { Pupil, KS2Data, StylusCriteriaScore } from '../types'

const mockData: KS2Data = generateMockData();

export default function InteractiveTables() {
  const { levels, ks2_criteria, strands, stylus_criteria, classes, pupils, writingPieces } = mockData
  const [selectedClasses, setSelectedClasses] = useState<string[]>(classes.map(c => c.name));
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedPupil, setSelectedPupil] = useState<Pupil | null>(null)
  const [selectedCriteria, setSelectedCriteria] = useState<string>("")
  const [selectedPieces, setSelectedPieces] = useState<string[]>(writingPieces.map(piece => piece.name));
  const [displayMode, setDisplayMode] = useState<'confidence' | 'score'>('confidence')
  const [pupilDialogOpen, setPupilDialogOpen] = useState(false);
  const [selectedPupilForDialog, setSelectedPupilForDialog] = useState<Pupil | null>(null);
  const [criteriaLevelStrandDialogOpen, setCriteriaLevelStrandDialogOpen] = useState(false);
  const [selectedCriteriaLevelStrand, setSelectedCriteriaLevelStrand] = useState<{ type: 'criteria' | 'level' | 'strand', value: string } | null>(null);

  // Filter pupils based on selected class
  const filteredPupils = useMemo(() => {
    return pupils.filter(pupil => 
      selectedClasses.includes(pupil.class.name)
    )
  }, [pupils, selectedClasses])

  const calculatePupilConfidenceData = useMemo(() => {
    return (filteredPupils: Pupil[], selectedPieces: string[]) => {
      return filteredPupils.map(pupil => {
        const ks2Data: { [ks2: string]: { negative: number, positive: number, averageScore: number | null } } = {};
        const strandData: { [strand: string]: { negative: number, positive: number, averageScore: number | null } } = {};

        ks2_criteria.forEach(ks2 => {
          const relevantScores = pupil.stylus_criteria_scores.filter(score => 
            stylus_criteria.find(sc => sc.name === score.stylus_criteria.name && sc.ks2_criteria.name === ks2.name) &&
            selectedPieces.includes(score.piece.writingPiece.name)
          );
          ks2Data[ks2.name] = calculateConfidence(relevantScores);
        });

        strands.forEach(strand => {
          const relevantScores = pupil.stylus_criteria_scores.filter(score =>
            stylus_criteria.find(sc => sc.name === score.stylus_criteria.name && sc.strand.name === strand.name) &&
            selectedPieces.includes(score.piece.writingPiece.name)
          );
          strandData[strand.name] = calculateConfidence(relevantScores);
        });

        return { pupil, ks2Data, strandData };
      });
    };
  }, [ks2_criteria, strands, stylus_criteria]);

  const pupilConfidenceData = useMemo(() => {
    return calculatePupilConfidenceData(filteredPupils, selectedPieces);
  }, [calculatePupilConfidenceData, filteredPupils, selectedPieces]);

  // Use the memoized pupil data to create the ks2Table
  const ks2Table = useMemo(() => {
    const table: { [level: string]: { [ks2: string]: { [pupil: string]: { negative: number, positive: number, averageScore: number | null } } } } = {};

    levels.forEach(level => {
      table[level.name] = {};
      ks2_criteria.filter(ks2 => ks2.level.name === level.name).forEach(ks2 => {
        table[level.name][ks2.name] = {};
        pupilConfidenceData.forEach(({ pupil, ks2Data }) => {
          table[level.name][ks2.name][pupil.name] = ks2Data[ks2.name];
        });
      });
    });

    return table;
  }, [levels, ks2_criteria, pupilConfidenceData]);

  // Use the memoized pupil data to create the strandTable
  const strandTable = useMemo(() => {
    const table: { [strand: string]: { [pupil: string]: { negative: number, positive: number, averageScore: number | null } } } = {};

    strands.forEach(strand => {
      table[strand.name] = {};
      pupilConfidenceData.forEach(({ pupil, strandData }) => {
        table[strand.name][pupil.name] = strandData[strand.name];
      });
    });

    return table;
  }, [strands, pupilConfidenceData]);

  const handleCellClick = (pupil: Pupil, criteria: string) => {
    setSelectedPupil(pupil);
    setSelectedCriteria(criteria);
    setDialogOpen(true);
  }

  const handlePupilClick = (pupil: Pupil) => {
    setSelectedPupilForDialog(pupil);
    setPupilDialogOpen(true);
  };

  const handleCriteriaLevelStrandClick = (type: 'criteria' | 'level' | 'strand', value: string) => {
    setSelectedCriteriaLevelStrand({ type, value });
    setCriteriaLevelStrandDialogOpen(true);
  };

  return (
    <Box sx={{ 
      padding: 8, 
      '& > *:not(:last-child)': { marginBottom: 8 },
      maxWidth: '1600px',
      margin: '0 auto',
    }}>
      <FilterControls
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        classes={classes}
        selectedPieces={selectedPieces}
        setSelectedPieces={setSelectedPieces}
        writingPieces={writingPieces}
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
      />

      <ConfidenceTable
        title="KS2 Criteria"
        data={ks2Table}
        filteredPupils={filteredPupils}
        displayMode={displayMode}
        handleCellClick={handleCellClick}
        handlePupilClick={handlePupilClick}
        handleCriteriaLevelStrandClick={handleCriteriaLevelStrandClick}
      />

      <ConfidenceTable
        title="Strands"
        data={strandTable}
        filteredPupils={filteredPupils}
        displayMode={displayMode}
        handleCellClick={handleCellClick}
        handlePupilClick={handlePupilClick}
        handleCriteriaLevelStrandClick={handleCriteriaLevelStrandClick}
      />

      <PupilDetailsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        selectedPupil={selectedPupil}
        selectedCriteria={selectedCriteria}
        ks2Table={ks2Table}
        strandTable={strandTable}
        ks2_criteria={ks2_criteria}
        strands={strands}
        stylus_criteria={stylus_criteria}
        selectedPieces={selectedPieces}
      />

      <PupilDialog
        open={pupilDialogOpen}
        onClose={() => setPupilDialogOpen(false)}
        pupil={selectedPupilForDialog}
      />

      <CriteriaLevelStrandDialog
        open={criteriaLevelStrandDialogOpen}
        onClose={() => setCriteriaLevelStrandDialogOpen(false)}
        selectedItem={selectedCriteriaLevelStrand}
        ks2Table={ks2Table}
        strandTable={strandTable}
        ks2_criteria={ks2_criteria}
        strands={strands}
        levels={levels}
        filteredPupils={filteredPupils}
      />
    </Box>
  )
}