import { useMemo, useState } from 'react';
import { KS2Criteria, Strand, Level, Pupil, StylusCriteria, PupilWritingPiece } from '../../types';
import ConfidenceBar from '../ConfidenceBar';
import BaseDialog from '../BaseDialog';

interface CriteriaLevelStrandDialogProps {
  open: boolean;
  onClose: () => void;
  selectedItem: { type: 'criteria' | 'level' | 'strand'; value: string } | null;
  ks2Table: any;
  strandTable: any;
  ks2_criteria: KS2Criteria[];
  strands: Strand[];
  levels: Level[];
  filteredPupils: Pupil[];
}

export default function CriteriaLevelStrandDialog({
  open,
  onClose,
  selectedItem,
  ks2Table,
  strandTable,
  ks2_criteria,
  filteredPupils,
}: CriteriaLevelStrandDialogProps) {
  const [splitExamples, setSplitExamples] = useState(false);
  const [groupBy, setGroupBy] = useState<'criteria' | 'piece' | 'level' | 'strand'>('criteria');

  const dialogTitle = useMemo(() => {
    if (!selectedItem) return '';
    const { type, value } = selectedItem;
    return `${type.charAt(0).toUpperCase() + type.slice(1)}: ${value}`;
  }, [selectedItem]);

  const getConfidenceData = (pupil: Pupil) => {
    if (!selectedItem) return null;
    const { type, value } = selectedItem;
    if (type === 'criteria') {
      const level = ks2_criteria.find(k => k.name === value)?.level;
      return level ? ks2Table[level.name]?.[value]?.[pupil.name] : undefined;
    }
    if (type === 'strand') return strandTable[value]?.[pupil.name];
    if (type === 'level') {
      const criteriaInLevel = ks2_criteria.filter(k => k.level.name === value);
      const confidenceData = criteriaInLevel.map(criteria => 
        ks2Table[value]?.[criteria.name]?.[pupil.name]
      ).filter(Boolean);

      if (!confidenceData.length) return null;

      return {
        negative: confidenceData.reduce((sum, data) => sum + data.negative, 0),
        positive: confidenceData.reduce((sum, data) => sum + data.positive, 0),
        averageScore: confidenceData.reduce((sum, data) => sum + (data.averageScore || 0), 0) / confidenceData.length,
      };
    }
  };

  const topSectionItems = useMemo(() => {
    if (!selectedItem) return [];

    let totalNegative = 0;
    let totalPositive = 0;
    let totalScore = 0;
    let count = 0;
    let pupilCount = 0;

    filteredPupils.forEach(pupil => {
      const confidenceData = getConfidenceData(pupil);
      if (confidenceData) {
        totalNegative += confidenceData.negative;
        totalPositive += confidenceData.positive;
        if (confidenceData.averageScore != null) {
          totalScore += confidenceData.averageScore;
          count++;
        }
        pupilCount++;
      }
    });

    return [
      { label: "Pupils", value: pupilCount },
      { 
        label: "Overall Confidence", 
        value: <ConfidenceBar negative={totalNegative} positive={totalPositive} />
      },
      { 
        label: "Average Score", 
        value: count > 0 ? (totalScore / count).toFixed(2) : 'N/A'
      }
    ];
  }, [selectedItem, filteredPupils, ks2Table, strandTable, ks2_criteria]);

  const examples = useMemo(() => {
    if (!selectedItem) return undefined;

    const positiveExamples: Record<string, any[]> = {};
    const negativeExamples: Record<string, any[]> = {};

    filteredPupils.forEach(pupil => {
      pupil.stylus_criteria_scores.forEach(score => {
        const key = (() => {
          if (groupBy === 'criteria') return score.stylus_criteria.name;
          if (groupBy === 'piece') return score.piece.writingPiece.name;
          return score[groupBy as 'level' | 'strand'].name;
        })();
        if (!positiveExamples[key]) positiveExamples[key] = [];
        if (!negativeExamples[key]) negativeExamples[key] = [];

        score.examples.positive.forEach(example => {
          positiveExamples[key].push({
            example,
            piece: score.piece,
            criteria: score.stylus_criteria.name,
            level: score.level.name,
            strand: score.strand.name,
            pupil: pupil.name,
            label: pupil.name
          });
        });

        score.examples.negative.forEach(example => {
          negativeExamples[key].push({
            example,
            piece: score.piece,
            criteria: score.stylus_criteria.name,
            level: score.level.name,
            strand: score.strand.name,
            pupil: pupil.name,
            label: pupil.name
          });
        });
      });
    });

    return { positive: positiveExamples, negative: negativeExamples };
  }, [selectedItem, filteredPupils, groupBy]);

  const groupByOptions = useMemo(() => {
    const options: ('piece' | 'strand' | 'level' | 'criteria')[] = 
      selectedItem?.type === 'strand' 
        ? ['criteria', 'level', 'piece']
        : ['strand', 'piece'];
    return options;
  }, [selectedItem]);

  return (
    <BaseDialog 
      open={open} 
      onClose={onClose} 
      title={dialogTitle} 
      maxWidth="lg" 
      fullWidth
      splitExamples={splitExamples}
      onSplitExamplesChange={setSplitExamples}
      topSectionItems={topSectionItems}
      groupBy={groupBy}
      onGroupByChange={(_, newGroupBy) => newGroupBy && setGroupBy(newGroupBy)}
      examples={examples}
      groupByOptions={groupByOptions}
    />
  );
}