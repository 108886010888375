import { WritingPiece, KS2Data, Class, Pupil, StylusCriteriaScore, Strand, Level, KS2Criteria, StylusCriteria, PupilWritingPiece, Scan } from "../types";

export default function generateMockData(): KS2Data {
    const strands: Strand[] = [
        { name: "Reading Comprehension" },
        { name: "Writing Composition" },
        { name: "Grammar and Punctuation" },
        { name: "Vocabulary" },
        { name: "Speaking and Listening" },
        { name: "Spelling" },
        { name: "Handwriting" },
        { name: "Text Analysis" }
    ];

    const levels: Level[] = [
        { name: "Working towards the expected standard" },
        { name: "Working at the expected standard" },
        { name: "Working at greater depth" }
    ];

    const ks2_criteria: KS2Criteria[] = [
        { name: "Understand main ideas", level: levels[0] },
        { name: "Identify basic text features", level: levels[0] },
        { name: "Use simple punctuation", level: levels[0] },
        { name: "Write simple sentences", level: levels[0] },
        { name: "Comprehend explicit information", level: levels[1] },
        { name: "Use varied sentence structures", level: levels[1] },
        { name: "Apply grammar rules consistently", level: levels[1] },
        { name: "Use descriptive vocabulary", level: levels[1] },
        { name: "Analyze complex texts", level: levels[2] },
        { name: "Write with sophisticated style", level: levels[2] },
        { name: "Use advanced literary techniques", level: levels[2] }
    ];

    const stylus_criteria: StylusCriteria[] = [
        { name: "Identify main topic", strand: strands[0], ks2_criteria: ks2_criteria[0] },
        { name: "Recognize text structure", strand: strands[0], ks2_criteria: ks2_criteria[1] },
        { name: "Use capital letters and full stops", strand: strands[2], ks2_criteria: ks2_criteria[2] },
        { name: "Construct basic sentences", strand: strands[1], ks2_criteria: ks2_criteria[3] },
        { name: "Extract key information", strand: strands[0], ks2_criteria: ks2_criteria[4] },
        { name: "Use compound sentences", strand: strands[1], ks2_criteria: ks2_criteria[5] },
        { name: "Apply subject-verb agreement", strand: strands[2], ks2_criteria: ks2_criteria[6] },
        { name: "Use adjectives effectively", strand: strands[3], ks2_criteria: ks2_criteria[7] },
        { name: "Interpret figurative language", strand: strands[7], ks2_criteria: ks2_criteria[8] },
        { name: "Use varied sentence openings", strand: strands[1], ks2_criteria: ks2_criteria[9] },
        { name: "Employ metaphors and similes", strand: strands[1], ks2_criteria: ks2_criteria[10] },
        { name: "Use correct spelling patterns", strand: strands[5], ks2_criteria: ks2_criteria[6] },
        { name: "Maintain consistent handwriting", strand: strands[6], ks2_criteria: ks2_criteria[3] },
        { name: "Engage in group discussions", strand: strands[4], ks2_criteria: ks2_criteria[4] },
        { name: "Analyze author's purpose", strand: strands[7], ks2_criteria: ks2_criteria[8] }
    ];

    const writingPieces: WritingPiece[] = [
        { name: "Newspaper Article" },
        { name: "Persuasive Speech" },
        { name: "Descriptive Essay" },
        { name: "Short Story" },
        { name: "Book Review" }
    ];

    const classes: Class[] = [
        { name: "Class A" },
        { name: "Class B" },
        { name: "Class C" },
        { name: "Class D" }
    ];

    function generatePupil(index: number): Pupil {
        const class_index = index % classes.length;
        const pupil_class = classes[class_index];
        const num_criteria = Math.floor(Math.random() * 3) + 11;
        const selected_criteria = shuffle(stylus_criteria).slice(0, num_criteria);
        const selected_pieces = shuffle(writingPieces).slice(0, Math.floor(Math.random() * 5) + 1);
        const writingPieceList: PupilWritingPiece[] = [];

        selected_pieces.forEach((piece, pieceIndex) => {
            const count = Math.random() < 0.75 ? 1 : 2;
            for (let i = 0; i < count; i++) {
                const pieceId = `${index + 1}-${piece.name}-${i + 1}`;
                writingPieceList.push({
                    id: pieceId,
                    writingPiece: piece,
                    scan: { pageCount: Math.floor(Math.random() * 5) + 1 }
                });
            }
        });

        return {
            name: `Pupil ${index + 1}`,
            class: pupil_class,
            stylus_criteria_scores: selected_criteria.flatMap(criteria =>
                writingPieceList.map(pupilWritingPiece => {
                    const score: StylusCriteriaScore = {
                        stylus_criteria: criteria,
                        piece: pupilWritingPiece,
                        score: calculateScore(Math.floor(Math.random() * 3), Math.floor(Math.random() * 3)),
                        examples: {
                            negative: Array.from({ length: Math.floor(Math.random() * 3) }, () =>
                                `An excerpt from student's ${pupilWritingPiece.writingPiece.name.toLowerCase()} that provides negative evidence`
                            ),
                            positive: Array.from({ length: Math.floor(Math.random() * 3) }, () =>
                                `An excerpt from student's ${pupilWritingPiece.writingPiece.name.toLowerCase()} that provides positive evidence`
                            )
                        },
                        ks2_criteria: criteria.ks2_criteria,
                        level: levels.find(l => l.name === criteria.ks2_criteria.level.name) || levels[0],
                        strand: strands.find(s => s.name === criteria.strand.name) || strands[0]
                    };
                    return score;
                })
            ),
            writingPieceList
        };
    }

    function calculateScore(positiveCount: number, negativeCount: number): number | null {
        if (positiveCount === 0 && negativeCount === 0) return null;
        return positiveCount / (positiveCount + negativeCount);
    }

    function shuffle<T>(array: T[]): T[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const pupils = Array.from({ length: 96 }, (_, i) => generatePupil(i));

    return {
        strands,
        levels,
        ks2_criteria,
        stylus_criteria,
        pupils,
        writingPieces,
        classes
    };
}