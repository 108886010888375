import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';  // Add this import

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  logo: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  navLink: {
    textDecoration: 'none',
    marginLeft: '1rem',
  },
};

const Header = () => {
  const theme = useTheme();  // Add this line
  return (
    <header style={{...styles.header, backgroundColor: theme.palette.primary.main}}>
      <Link to="/" style={{...styles.logo, color: theme.palette.primary.contrastText}}>Prototype Gallery</Link>
      <nav>
        <Link to="/" style={{...styles.navLink, color: theme.palette.primary.contrastText}}>Home</Link>
        {/* Add more navigation links here as needed */}
      </nav>
    </header>
  );
}

export default Header;