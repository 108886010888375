import { useCallback, useState, Dispatch, SetStateAction, useMemo } from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, Slider, SelectChangeEvent } from '@mui/material';
import { WritingPiece, KS2Criteria, Level } from '../../types';
import ChipFilter from '../ChipFilter';

interface FilterSectionProps {
  selectedPieces: WritingPiece[];
  setSelectedPieces: Dispatch<SetStateAction<WritingPiece[]>>;
  writingPieces: WritingPiece[];
  ks2Criteria: KS2Criteria[];
  levels: Level[];
  criteriaScoreFilter: { 
    level: Level | null;
    criteria: KS2Criteria | null; 
    minScore: number; 
    maxScore: number; 
  } | null;
  setCriteriaScoreFilter: Dispatch<SetStateAction<{ 
    level: Level | null;
    criteria: KS2Criteria | null; 
    minScore: number; 
    maxScore: number; 
  } | null>>;
}

export function FilterSection({
  selectedPieces,
  setSelectedPieces,
  writingPieces,
  ks2Criteria,
  levels,
  criteriaScoreFilter,
  setCriteriaScoreFilter
}: FilterSectionProps) {

  const handlePieceToggle = useCallback((name: string) => {
    setSelectedPieces(prev => 
      prev.some(p => p.name === name)
        ? prev.filter(p => p.name !== name)
        : [...prev, writingPieces.find(piece => piece.name === name)!]
    );
  }, [setSelectedPieces, writingPieces]);

  const handleSelectAll = useCallback(() => {
    setSelectedPieces(writingPieces);
  }, [writingPieces, setSelectedPieces]);

  const handleClearSelection = useCallback(() => {
    setSelectedPieces([]);
  }, [setSelectedPieces]);

  const handleLevelChange = (event: SelectChangeEvent<string>) => {
    const selectedLevel = event.target.value === '' ? null : levels.find(l => l.name === event.target.value) || null;
    setCriteriaScoreFilter(prev => ({
      ...prev,
      level: selectedLevel,
      criteria: null,
      minScore: 0,
      maxScore: 1
    }));
  };

  const handleCriteriaChange = (event: SelectChangeEvent<string>) => {
    const selectedCriteria = event.target.value === '' ? null : ks2Criteria.find(c => c.name === event.target.value) || null;
    setCriteriaScoreFilter(prev => ({
      level: prev?.level ?? null,
      criteria: selectedCriteria,
      minScore: prev?.minScore ?? 0,
      maxScore: prev?.maxScore ?? 1
    }));
  };

  const filteredCriteria = useMemo(() => 
    criteriaScoreFilter?.level ? ks2Criteria.filter(c => c.level.name === criteriaScoreFilter.level?.name) : ks2Criteria
  , [ks2Criteria, criteriaScoreFilter?.level]);

  const handleScoreChange = (event: Event, newValue: number | number[]) => {
    const [minScore, maxScore] = newValue as number[];
    setCriteriaScoreFilter(prev => prev ? { ...prev, minScore, maxScore } : null);
  };

  const selectedNames = selectedPieces.map(p => p.name);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ChipFilter
        label="Writing Pieces"
        items={writingPieces.map(piece => ({ id: piece.name, label: piece.name }))}
        selectedItems={selectedNames}
        onToggle={handlePieceToggle}
        onSelectAll={handleSelectAll}
        onClearSelection={handleClearSelection}
        vertical={true}
        sx={{ height: '200px' }}
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">Score Filter</Typography>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel>Level</InputLabel>
          <Select
            value={criteriaScoreFilter?.level?.name ?? ''}
            onChange={handleLevelChange}
            label="Level"
          >
            <MenuItem value="">Any</MenuItem>
            {levels.map(l => (
              <MenuItem key={l.name} value={l.name}>{l.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel>KS2 Criteria</InputLabel>
          <Select
            value={criteriaScoreFilter?.criteria?.name ?? ''}
            onChange={handleCriteriaChange}
            label="KS2 Criteria"
          >
            <MenuItem value="">Any</MenuItem>
            {filteredCriteria.map(c => (
              <MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {criteriaScoreFilter && (
          <Box sx={{ mt: 2 }}>
            <Typography gutterBottom>Score Range</Typography>
            <Slider
              value={[criteriaScoreFilter.minScore, criteriaScoreFilter.maxScore]}
              onChange={handleScoreChange}
              valueLabelDisplay="on"
              min={0}
              max={1}
              step={0.1}
              sx={{ mt: 4 }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FilterSection;