import React, { useState, useMemo } from 'react';
import { ConfidenceBar } from '..';
import { Pupil, KS2Criteria, Strand, StylusCriteria } from '../../types';
import BaseDialog from '../BaseDialog';

interface PupilDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  selectedPupil: Pupil | null;
  selectedCriteria: string;
  ks2Table: any;
  strandTable: any;
  ks2_criteria: KS2Criteria[];
  strands: Strand[];
  stylus_criteria: StylusCriteria[];
  selectedPieces: string[];
}

export default function PupilDetailsDialog({
  open,
  onClose,
  selectedPupil,
  selectedCriteria,
  ks2Table,
  strandTable,
  ks2_criteria,
  strands,
  stylus_criteria,
  selectedPieces
}: PupilDetailsDialogProps) {
  const [splitExamples, setSplitExamples] = useState(true);
  const [groupBy, setGroupBy] = useState<'piece' | 'strand' | 'level' | 'criteria'>('piece');

  const isStrand = useMemo(() => strands.some(s => s.name === selectedCriteria), [strands, selectedCriteria]);

  const groupByOptions = useMemo(() => {
    const options: ('piece' | 'strand' | 'level' | 'criteria')[] = isStrand
      ? ['piece', 'level', 'criteria']
      : ['piece', 'strand'];
    return options;
  }, [isStrand]);

  const examples = useMemo(() => {
    if (!selectedPupil || !selectedCriteria) return undefined;

    const filterScores = (type: 'positive' | 'negative') =>
      selectedPupil.stylus_criteria_scores
        .filter(score =>
          stylus_criteria.find(sc => sc.name === score.stylus_criteria.name &&
            (sc.ks2_criteria.name === selectedCriteria || sc.strand.name === selectedCriteria)) &&
          (selectedPieces.includes("All") || selectedPieces.includes(score.piece.writingPiece.name))
        )
        .flatMap(score => score.examples[type].map(example => ({ 
          example, 
          piece: score.piece.writingPiece.name, 
          criteria: score.stylus_criteria.name,
          level: score.level.name,
          strand: score.strand.name,
          label: groupBy === 'piece' ? '' : score.piece.writingPiece.name
        })));

    const groupExamples = (examples: ReturnType<typeof filterScores>) =>
      examples.reduce((acc, example) => {
        const key = (() => {
          switch (groupBy) {
            case 'piece': return example.piece;
            case 'strand': return example.strand;
            case 'level': return example.level;
            case 'criteria': return example.criteria;
            default: return example.piece;
          }
        })();
        if (!acc[key]) acc[key] = [];
        acc[key].push(example);
        return acc;
      }, {} as Record<string, typeof examples>);

    return {
      positive: groupExamples(filterScores('positive')),
      negative: groupExamples(filterScores('negative'))
    };
  }, [selectedPupil, selectedCriteria, stylus_criteria, selectedPieces, groupBy]);

  if (!selectedPupil || !selectedCriteria) return null;

  const getConfidenceData = () => {
    const data = ks2Table[ks2_criteria.find(k => k.name === selectedCriteria)?.level.name || ""]?.[selectedCriteria]?.[selectedPupil.name] ||
      strandTable[selectedCriteria]?.[selectedPupil.name] ||
      { negative: 0, positive: 0, averageScore: null };
    return data;
  };

  const topSectionItems = [
    ...(strands.find(s => s.name === selectedCriteria)
      ? [{ label: "Strand", value: selectedCriteria }]
      : [
        { label: "Level", value: ks2_criteria.find(k => k.name === selectedCriteria)?.level.name },
        { label: "KS2 Criteria", value: selectedCriteria },
      ]
    ),
    {
      label: "Confidence",
      value: <ConfidenceBar {...getConfidenceData()} />
    },
    {
      label: "Raw Score",
      value: getConfidenceData().averageScore !== null 
        ? getConfidenceData().averageScore.toFixed(2)
        : 'N/A'
    }
  ];

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={selectedPupil.name}
      maxWidth="lg"
      topSectionItems={topSectionItems}
      splitExamples={splitExamples}
      onSplitExamplesChange={setSplitExamples}
      groupBy={groupBy}
      onGroupByChange={(_, newGroupBy) => newGroupBy && groupByOptions.includes(newGroupBy) && setGroupBy(newGroupBy as typeof groupBy)}
      examples={examples}
      groupByOptions={groupByOptions}
    />
  );
}