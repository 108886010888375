import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Checkbox } from '@mui/material';
import { NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material';

interface ScanDisplayProps {
  currentPage: number;
  totalPages: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  selectable?: boolean;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
}

const A4_ASPECT_RATIO = Math.sqrt(2);

const ScanDisplay = ({
  currentPage,
  totalPages,
  onPreviousPage,
  onNextPage,
  selectable = false,
  selected = false,
  onSelect,
}: ScanDisplayProps) => {
  const [zoom, setZoom] = useState(1);
  const [isCommandPressed, setIsCommandPressed] = useState(false);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = width * A4_ASPECT_RATIO;
        setContainerDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.2, 3));
  const handleZoomOut = () => {
    setZoom(prev => {
      const newZoom = Math.max(prev - 0.2, 1);
      if (newZoom <= 1 && containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      return newZoom;
    });
  };

  const handleZoom = (event: React.MouseEvent<SVGSVGElement>) => {
    (event.metaKey || event.ctrlKey) ? handleZoomOut() : handleZoomIn();
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.key === 'Control') {
        setIsCommandPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.key === 'Control') {
        setIsCommandPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {selectable && (
        <Box
          sx={{
            position: 'absolute',
            top: 6,
            right: 6,
            zIndex: 2,
          }}
        >
          <Checkbox
            checked={selected}
            onChange={(e) => onSelect?.(e.target.checked)}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
            }}
          />
        </Box>
      )}
      <Box 
        ref={containerRef}
        sx={{ 
          width: '100%',
          height: 0,
          paddingBottom: `calc(${A4_ASPECT_RATIO * 100}% - 4px)`,
          overflow: zoom > 1 ? 'auto' : 'hidden',
          cursor: isCommandPressed ? 'zoom-out' : 'zoom-in',
          position: 'relative',
          border: selected ? '2px solid #1976d2' : '2px solid transparent',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: `scale(${zoom})`,
            transformOrigin: 'top left',
            lineHeight: 0,
          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${containerDimensions.width} ${containerDimensions.height}`}
            onClick={handleZoom}
          >
            <rect x="0" y="0" width="100%" height="100%" fill="#ffffff" stroke="#000000" strokeWidth="2" />
            {[...Array(12)].map((_, i) => (
              <line 
                key={i} 
                x1={containerDimensions.width * 0.1}
                y1={containerDimensions.height * (0.12 + i * 0.06)}
                x2={containerDimensions.width * 0.9}
                y2={containerDimensions.height * (0.12 + i * 0.06)}
                stroke="#cccccc" 
                strokeWidth="1" 
              />
            ))}
            <text x={containerDimensions.width / 2} y={containerDimensions.height / 2} fontFamily="Arial" fontSize="24" fill="#999999" textAnchor="middle">Scan Placeholder</text>
            <text x={containerDimensions.width / 2} y={containerDimensions.height * 0.56} fontFamily="Arial" fontSize="18" fill="#999999" textAnchor="middle">Page {currentPage}</text>
          </svg>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
        <IconButton onClick={onPreviousPage} disabled={currentPage === 1}><NavigateBefore /></IconButton>
        <Typography sx={{ mx: 2 }}>Page {currentPage} of {totalPages}</Typography>
        <IconButton onClick={onNextPage} disabled={currentPage === totalPages}><NavigateNext /></IconButton>
        <IconButton onClick={handleZoomOut} disabled={zoom <= 1}><ZoomOut /></IconButton>
        <Typography sx={{ mx: 1 }}>{(zoom * 100).toFixed(0)}%</Typography>
        <IconButton onClick={handleZoomIn} disabled={zoom >= 3}><ZoomIn /></IconButton>
      </Box>
    </Box>
  );
};

export default ScanDisplay;