   import { Box, LinearProgress } from "@mui/material"

   const ConfidenceBar = ({ negative, positive }: { negative: number, positive: number }) => {
     const maxScore = Math.max(negative, positive, 0.3)
     const negativeWidth = (negative / maxScore) * 100
     const positiveWidth = (positive / maxScore) * 100

     // Calculate opacity based on the value, starting from 0
     const negativeOpacity = negative / maxScore
     const positiveOpacity = positive / maxScore

     return (
       <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
         <Box sx={{ width: 'calc(50% - 0.5px)', display: 'flex', justifyContent: 'flex-end' }}>
           <LinearProgress
             variant="determinate"
             value={negativeWidth}
             sx={{ 
               width: '100%', 
               transform: 'rotate(180deg)', 
               backgroundColor: 'transparent',
               '& .MuiLinearProgress-bar': {
                 backgroundColor: `rgba(239, 68, 68, ${negativeOpacity})`,
               },
               height: 12,
             }}
           />
         </Box>
         <Box sx={{ width: '.1rem', height: 16, backgroundColor: 'black', margin: '0 .2rem' }} />
         <Box sx={{ width: 'calc(50% - 0.5px)' }}>
           <LinearProgress
             variant="determinate"
             value={positiveWidth}
             sx={{
               width: '100%',
               backgroundColor: 'transparent',
               '& .MuiLinearProgress-bar': {
                 backgroundColor: `rgba(34, 197, 94, ${positiveOpacity})`,
               },
               height: 12,
             }}
           />
         </Box>
       </Box>
     )
   }

   export default ConfidenceBar