import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import BarChartIcon from '@mui/icons-material/BarChart';
import ImageIcon from '@mui/icons-material/Image';

const prototypes = [
  { id: 'ks2-teacher-report', name: 'KS2 Teacher Report', path: '/ks2-teacher-report', Icon: TableViewIcon },
  { id: 'scan-viewer', name: 'Scan Viewer', path: '/scan-viewer', Icon: ImageIcon },
  // Add more prototypes here
];

function Home() {
  return (
    <Grid container spacing={3} sx={{ padding: 3 }}>
      {prototypes.map((prototype) => {
        const IconComponent = prototype.Icon;
        return (
          <Grid item xs={12} sm={6} md={4} key={prototype.id}>
            <Card 
              component={Link} 
              to={prototype.path} 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                textDecoration: 'none',
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 3,
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <IconComponent sx={{ fontSize: 60, mb: 2, color: 'primary.main' }} />
                <Typography variant="h6" component="h2" gutterBottom>
                  {prototype.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Home;