import React, { useCallback } from 'react';
import { Box, Select, MenuItem, TextField, FormControlLabel, Switch, Chip, Button } from "@mui/material";
import { WritingPiece, Class } from '../../types';
import ChipFilter from '../ChipFilter';

interface FilterControlsProps {
  selectedClasses: string[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<string[]>>;
  classes: Class[];
  selectedPieces: string[];
  setSelectedPieces: React.Dispatch<React.SetStateAction<string[]>>;
  writingPieces: WritingPiece[];
  displayMode: 'confidence' | 'score';
  setDisplayMode: (mode: 'confidence' | 'score') => void;
}

const FilterControls = React.memo(({
  selectedClasses,
  setSelectedClasses,
  classes,
  selectedPieces,
  setSelectedPieces,
  writingPieces,
  displayMode,
  setDisplayMode
}: FilterControlsProps) => {
  const handlePieceToggle = useCallback((piece: string) => () => {
    setSelectedPieces((prevSelectedPieces: string[]) => {
      if (prevSelectedPieces.includes(piece)) {
        return prevSelectedPieces.filter((p) => p !== piece);
      } else {
        return [...prevSelectedPieces, piece];
      }
    });
  }, [setSelectedPieces]);

  const handleSelectAll = useCallback(() => {
    setSelectedPieces(writingPieces.map(piece => piece.name));
  }, [writingPieces, setSelectedPieces]);

  const handleClearSelection = useCallback(() => {
    setSelectedPieces([]);
  }, [setSelectedPieces]);

  const handleClassToggle = useCallback((className: string) => () => {
    setSelectedClasses((prevSelectedClasses: string[]) => {
      if (prevSelectedClasses.includes(className)) {
        return prevSelectedClasses.filter((c) => c !== className);
      } else {
        return [...prevSelectedClasses, className];
      }
    });
  }, [setSelectedClasses]);

  const handleSelectAllClasses = useCallback(() => {
    setSelectedClasses(classes.map(c => c.name));
  }, [classes, setSelectedClasses]);

  const handleClearClassSelection = useCallback(() => {
    setSelectedClasses([]);
  }, [setSelectedClasses]);

  const selectedPieceNames = selectedPieces;
  const selectedClassNames = selectedClasses;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4, maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'flex-start', rowGap: 0 }}>
        <ChipFilter
          label="Classes"
          items={classes.map(c => ({ id: c.name, label: c.name }))}
          selectedItems={selectedClassNames}
          onToggle={(id) => handleClassToggle(id)()}
          onSelectAll={handleSelectAllClasses}
          onClearSelection={handleClearClassSelection}
          sx={{ width: '300px' }}
        />

        <ChipFilter
          label="Writing Pieces"
          items={writingPieces.map(p => ({ id: p.name, label: p.name }))}
          selectedItems={selectedPieceNames}
          onToggle={(id) => handlePieceToggle(id)()}
          onSelectAll={handleSelectAll}
          onClearSelection={handleClearSelection}
          sx={{ width: '480px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '120px' }}>
          <label htmlFor="display-mode-select">Display Mode</label>
          <Select
            id="display-mode-select"
            value={displayMode}
            onChange={(e) => setDisplayMode(e.target.value as 'confidence' | 'score')}
            sx={{ height: '40px' }}
          >
            <MenuItem value="confidence">Confidence</MenuItem>
            <MenuItem value="score">Score</MenuItem>
          </Select>
        </Box>
      </Box>
    </Box>
  );
});

export default FilterControls;