import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from "@mui/material";
import ConfidenceCell from './ConfidenceCell';
import { Pupil } from '../../types';
import { alpha, Theme } from '@mui/material/styles';

interface ConfidenceTableProps {
  title: string;
  data: any;
  filteredPupils: Pupil[];
  displayMode: 'confidence' | 'score';
  handleCellClick: (pupil: Pupil, criteria: string) => void;
  handlePupilClick: (pupil: Pupil) => void;
  handleCriteriaLevelStrandClick: (type: 'criteria' | 'level' | 'strand', value: string) => void;
}

const tableStyles = {
  borderSpacing: 0,
  borderCollapse: 'separate',
  '& th, & td': {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: 'none',
    borderTop: 'none',
  },
  '& th': {
    backgroundColor: 'background.paper',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  '& tr th:first-of-type, & tr td:first-of-type': {
    borderLeft: '1px solid rgba(255, 255, 255, 1)',
  },
};

const stickyCellStyle = (left: number) => ({
  position: 'sticky',
  left,
  zIndex: 2,
  backgroundColor: 'background.paper',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
});

const clickableCellStyle = (theme: Theme) => ({
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.default, 0.96),
  },
});

const pupilHeaderCellStyle = {
  width: 100,
  minWidth: 100,
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

const ConfidenceTable = ({
  title,
  data,
  filteredPupils,
  displayMode,
  handleCellClick,
  handlePupilClick,
  handleCriteriaLevelStrandClick,
}: ConfidenceTableProps) => {

  const isCellClickable = (averageScore: number | null) => averageScore !== null;

  const renderEmptyState = () => (
    <Box
      sx={{
        position: 'absolute',
        top: 58,
        left: 400,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 10,
      }}
    >
      <Typography variant="h6">No pupils available</Typography>
      <Typography variant="body1">Please add pupils or adjust your filters.</Typography>
    </Box>
  );

  return (
    <div>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 4 }}>{title}</Typography>
      <Box sx={{ position: 'relative', overflowX: 'auto', border: '1px solid', borderRadius: '8px' }}>
        <Table sx={tableStyles}>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={() => ({ ...stickyCellStyle(0), width: 200, minWidth: 200 })}
                onClick={() => handleCriteriaLevelStrandClick(title === "KS2 Criteria" ? 'level' : 'strand', '')}
              >
                {title === "KS2 Criteria" ? "Level" : "Strand"}
              </TableCell>
              {title === "KS2 Criteria" && (
                <TableCell sx={() => ({ ...stickyCellStyle(200), width: 200, minWidth: 200 })}>KS2 Criteria</TableCell>
              )}
              {filteredPupils.length === 0 ? (
                <TableCell>Pupils</TableCell>
              ) : (
                filteredPupils.map(pupil => (
                  <TableCell
                    key={pupil.name}
                    sx={pupilHeaderCellStyle}
                    onClick={() => handlePupilClick(pupil)}
                  >
                    {pupil.name}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([key, value]) => (
              <React.Fragment key={key}>
                {title === "KS2 Criteria" ? (
                  Object.entries(value as any).map(([ks2, pupilData], index) => (
                    <TableRow key={`${key}-${ks2}`}>
                      {index === 0 && (
                        <TableCell 
                          sx={(theme) => ({ ...stickyCellStyle(0), ...clickableCellStyle(theme), width: 200, minWidth: 200 })}
                          rowSpan={Object.keys(value as any).length}
                          onClick={() => handleCriteriaLevelStrandClick('level', key)}
                        >
                          {key}
                        </TableCell>
                      )}
                      <TableCell 
                        sx={(theme) => ({ ...stickyCellStyle(200), ...clickableCellStyle(theme), width: 200, minWidth: 200 })}
                        onClick={() => handleCriteriaLevelStrandClick('criteria', ks2)}
                      >
                        {ks2}
                      </TableCell>
                      {filteredPupils.map(pupil => {
                        const cellData = (pupilData as any)[pupil.name];
                        const isClickable = isCellClickable(cellData?.averageScore);
                        return (
                          <ConfidenceCell
                            key={`${key}-${ks2}-${pupil.name}`}
                            cellData={cellData}
                            displayMode={displayMode}
                            isClickable={isClickable}
                            handleCellClick={() => isClickable && handleCellClick(pupil, ks2)}
                          />
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow key={key}>
                    <TableCell 
                      sx={(theme) => ({ ...stickyCellStyle(0), ...clickableCellStyle(theme), width: 400, minWidth: 400 })}
                      onClick={() => handleCriteriaLevelStrandClick('strand', key)}
                    >
                      {key}
                    </TableCell>
                    {filteredPupils.map(pupil => {
                      const cellData = (value as any)[pupil.name];
                      const isClickable = isCellClickable(cellData?.averageScore);
                      return (
                        <ConfidenceCell
                          key={`${key}-${pupil.name}`}
                          cellData={cellData}
                          displayMode={displayMode}
                          isClickable={isClickable}
                          handleCellClick={() => isClickable && handleCellClick(pupil, key)}
                        />
                      );
                    })}
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {filteredPupils.length === 0 && renderEmptyState()}
      </Box>
    </div>
  );
};

const MemoizedConfidenceTable = React.memo(ConfidenceTable);

export default MemoizedConfidenceTable;