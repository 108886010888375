import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { WritingPiece, KS2Data } from '../../types';
import ScanDisplay from '../ScanDisplay';
import ScoresDialog from './ScoresDialog';

interface Scan {
  id: string;
  pupilName: string;
  writingPiece: WritingPiece;
  pageCount: number;
  class: string;
}

interface ScanItemProps {
  scan: Scan;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onOpenDialog: (scanId: string) => void;
}

interface ScanListProps {
  scans: Scan[];
  selectedScans: string[];
  setSelectedScans: React.Dispatch<React.SetStateAction<string[]>>;
}

const ScanItem = ({ scan, isSelected, onSelect, onOpenDialog }: ScanItemProps & { onOpenDialog: (scanId: string) => void }) => {
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <Box sx={{ width: 460, display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1, 
            fontWeight: 'bold', 
            color: 'primary.main',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {scan.pupilName}
        </Typography>
        <IconButton onClick={() => onOpenDialog(scan.id)} color="primary">
          <AssessmentIcon />
        </IconButton>
      </Box>
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 1,
          color: 'text.secondary',
          textAlign: 'left',
          width: '100%',
          margin: 0,
        }}
      >
        {scan.class}
      </Typography>
      <Typography 
        variant="h6" 
        sx={{ 
          mt: 1, 
          mb: 2, 
          color: 'text.secondary',
          textAlign: 'left',
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginTop: 0,
        }}
      >
        {scan.writingPiece.name}
      </Typography>
      <ScanDisplay
        currentPage={currentPage}
        totalPages={scan.pageCount}
        onPreviousPage={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        onNextPage={() => setCurrentPage(prev => Math.min(prev + 1, scan.pageCount))}
        selectable
        selected={isSelected}
        onSelect={() => onSelect(scan.id)}
      />
    </Box>
  );
};

const ScanList = ({ scans, selectedScans, setSelectedScans, data }: ScanListProps & { data: KS2Data | null }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<{ pupilName: string; writingPieceName: string; scores: any[] }>({ pupilName: '', writingPieceName: '', scores: [] });

  const handleOpenDialog = (scanId: string) => {
    const pupil = data?.pupils?.find(p => p.writingPieceList.some(wp => wp.id === scanId));
    const pupilWritingPiece = pupil?.writingPieceList?.find(wp => wp.id === scanId);
    
    const scores = pupil?.stylus_criteria_scores
      ?.filter(score => score.piece.id === scanId)
      .reduce((acc, score) => {
        const key = `${score.level.name}_${score.ks2_criteria.name}`;
        if (!acc[key]) {
          acc[key] = { sum: 0, count: 0 };
        }
        acc[key].sum += score.score || 0;
        acc[key].count++;
        return acc;
      }, {} as Record<string, { sum: number; count: number }>);

    const averagedScores = Object.entries(scores ?? {}).map(([level_criteria, { sum, count }]) => ({
      levelName: level_criteria.split('_')[0],
      criteriaName: level_criteria.split('_')[1],
      score: sum / count
    }));

    setDialogData({
      pupilName: pupil?.name || '',
      writingPieceName: pupilWritingPiece?.writingPiece.name || '',
      scores: averagedScores
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleSelect = (id: string) => {
    setSelectedScans(prev => 
      prev.includes(id) ? prev.filter(scanId => scanId !== id) : [...prev, id]
    );
  };

  return (
    <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', p: 2, height: '100%' }}>
      <Box sx={{ display: 'inline-flex', gap: 3 }}>
        {scans.map((scan: Scan) => (
          <ScanItem 
            key={scan.id}
            scan={scan} 
            isSelected={selectedScans.includes(scan.id)}
            onSelect={handleSelect}
            onOpenDialog={handleOpenDialog}
          />
        ))}
      </Box>
      <ScoresDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        pupilName={dialogData.pupilName}
        writingPieceName={dialogData.writingPieceName}
        scores={dialogData.scores}
        ks2Data={data!}
      />
    </Box>
  );
};

export default ScanList;