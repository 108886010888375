import React, { useState, useMemo } from 'react';
import { Pupil, StylusCriteriaScore } from '../../types';
import ConfidenceBar from '../ConfidenceBar';
import BaseDialog from '../BaseDialog';

interface PupilDialogProps {
  open: boolean;
  onClose: () => void;
  pupil: Pupil | null;
}

export default function PupilDialog({ open, onClose, pupil }: PupilDialogProps) {
  const [groupBy, setGroupBy] = useState<'criteria' | 'piece' | 'level' | 'strand'>('criteria');
  const [splitExamples, setSplitExamples] = useState(false);

  const examples = useMemo(() => {
    if (!pupil) return undefined;

    const mapExamples = (score: StylusCriteriaScore, type: 'positive' | 'negative') =>
      score.examples[type].map(example => ({ 
        example, 
        piece: score.piece.writingPiece.name, 
        criteria: score.stylus_criteria.name,
        level: score.level.name,
        strand: score.strand.name,
        label: groupBy === 'piece' ? '' : score.piece.writingPiece.name
      }));

    const groupExamples = (examples: ReturnType<typeof mapExamples>) =>
      examples.reduce((acc, example) => {
        const key = (() => {
          switch (groupBy) {
            case 'criteria': return example.criteria;
            case 'piece': return example.piece;
            case 'level': return example.level;
            case 'strand': return example.strand;
            default: return example.criteria;
          }
        })();
        if (!acc[key]) acc[key] = [];
        acc[key].push(example);
        return acc;
      }, {} as Record<string, typeof examples>);

    const positiveExamples = groupExamples(pupil.stylus_criteria_scores.flatMap(score => mapExamples(score, 'positive')));
    const negativeExamples = groupExamples(pupil.stylus_criteria_scores.flatMap(score => mapExamples(score, 'negative')));

    return { 
      positive: positiveExamples, 
      negative: negativeExamples 
    } as Record<'positive' | 'negative', Record<string, { example: string; piece: string; criteria: string; level: string; strand: string; label: string }[]>>;
  }, [pupil, groupBy]);

  if (!pupil) return null;

  const topSectionItems = [
    { label: "Class", value: pupil.class.name },
    { 
      label: "Overall Confidence", 
      value: <ConfidenceBar 
        negative={pupil.stylus_criteria_scores.reduce((sum, score) => sum + score.examples.negative.length, 0)}
        positive={pupil.stylus_criteria_scores.reduce((sum, score) => sum + score.examples.positive.length, 0)}
      />
    },
    { 
      label: "Average Score", 
      value: (pupil.stylus_criteria_scores.reduce((sum, score) => sum + (score.score || 0), 0) / pupil.stylus_criteria_scores.length).toFixed(2)
    }
  ];

  return (
    <BaseDialog 
      open={open} 
      onClose={onClose} 
      title={pupil.name}
      topSectionItems={topSectionItems}
      maxWidth="lg"
      splitExamples={splitExamples}
      onSplitExamplesChange={setSplitExamples}
      groupBy={groupBy}
      onGroupByChange={(_, newGroupBy) => newGroupBy && setGroupBy(newGroupBy)}
      examples={examples}
      groupByOptions={['criteria', 'piece', 'level', 'strand']}
    />
  );
}